:root {
  --white: #ffffff;
  --black: #000000;
  --darkblack: #181a1c;
  --graylight: #eaeced;
  --lightgary: #808190;
  --darkgray: #404d51;
  --offgreen: #499343;
  --lightblue: #f6f8fe;
  --orange: #fb8500;
  --fullcircle: 50%;
  /* --ipadsize: 6 * ((100vw - 320px) / 680); */
}
@font-face {
  font-family: "Alternate Gothic ATF";
  src: url("../fonts/AlternateGothicATF.eot");
  src: url("../fonts/AlternateGothicATF.eot?#iefix") format("embedded-opentype"),
    url("../fonts/AlternateGothicATF.woff") format("woff"),
    url("../fonts/AlternateGothicATF.ttf") format("truetype"),
    url("../fonts/AlternateGothicATF.svg#AlternateGothicATF") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Rileno Sans";
  src: url("../fonts/RilenoSans-Bold.eot");
  src: url("../fonts/RilenoSans-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/RilenoSans-Bold.woff") format("woff"),
    url("../fonts/RilenoSans-Bold.ttf") format("truetype"),
    url("../fonts/RilenoSans-Bold.svg#RilenoSans-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

.bg-gray {
  background-color: var(--graylight);
}

.leftarea {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.leftarea .logo img {
  width: 138px;
}
ul.profilearea li {
  margin: 0 1em;
  position: relative;
}
ul.profilearea li:last-child {
  margin-right: 0;
}
ul.profilearea li:first-child {
  margin-left: 0;
}
ul.profilearea li:first-child a {
  color: var(--darkgray);
}
ul.profilearea li img {
  margin-bottom: -3px;
}
html body .navbar-toggler-icon {
  height: auto;
}
html body .MuiButton-root {
  font-size: 1.875rem;
}
ul.nav li a.profile_area .count {
  border-radius: var(--fullcircle);
  background-color: var(--offgreen);
  width: 24px;
  height: 24px;
  font-size: calc(2px + var(--ipadsize));
  font-family: "Alternate Gothic ATF";
  color: var(--white);
  position: absolute;
  top: 0;
  right: 0;
  border: 2px solid var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
}
ul.nav li a.profile_area {
  border: 2px solid var(--lightgary);
  width: 53px;
  height: 53px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--fullcircle);
  font-size: calc(20px + var(--ipadsize));
  background-color: var(--white);
}
html body .MuiBottomNavigation-root {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  background-color: var(--lightblue);
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
  padding: 0 12px;
}
html body .MuiBottomNavigationAction-root {
  padding: 0;
}
html body .Mui-selected .MuiIcon-root,
html body .Mui-selected .MuiSvgIcon-root {
  display: none;
}
html body .MuiBottomNavigationAction-label.Mui-selected {
  font-family: "Rileno Sans";
  font-weight: bold;
  font-size: 20px;
  text-transform: capitalize;
  color: var(--orange);
}
html body .Mui-selected .MuiBottomNavigationAction-wrapper {
  border: 1px solid var(--orange);
  border-radius: 10px;
}

html body .MuiIcon-root {
  width: 1.5em;
  height: 1.5em;
}
html body .MuiIcon-root img {
  width: 100%;
}

@media (max-width: 991.98px) {
  ul.nav li a.profile_area .count {
    font-size: calc(12px + var(--ipadsize));
  }
  ul.nav li a.profile_area {
    font-size: calc(28px + var(--ipadsize));
  }
}
