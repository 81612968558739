@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?lmgf8t');
  src:  url('../fonts/icomoon.eot?lmgf8t#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?lmgf8t') format('truetype'),
    url('../fonts/icomoon.woff?lmgf8t') format('woff'),
    url('../fonts/icomoon.svg?lmgf8t#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cart:before {
  content: "\e900";
}
.icon-indianr .path1:before {
  content: "\e901";
  color: rgb(245, 245, 245);
}
.icon-indianr .path2:before {
  content: "\e902";
  margin-left: -1.181640625em;
  color: rgb(255, 255, 255);
}
.icon-indianr .path3:before {
  content: "\e903";
  margin-left: -1.181640625em;
  color: rgb(255, 255, 255);
}
.icon-indianr .path4:before {
  content: "\e904";
  margin-left: -1.181640625em;
  color: rgb(255, 255, 255);
}
.icon-indianr .path5:before {
  content: "\e905";
  margin-left: -1.181640625em;
  color: rgb(255, 255, 255);
}
.icon-indianr .path6:before {
  content: "\e906";
  margin-left: -1.181640625em;
  color: rgb(255, 255, 255);
}
.icon-announcement:before {
  content: "\e907";
}
.icon-misc:before {
  content: "\e908";
}
.icon-sales:before {
  content: "\e909";
}
.icon-expense .path1:before {
  content: "\e90a";
  color: rgb(0, 0, 0);
}
.icon-expense .path2:before {
  content: "\e90b";
  margin-left: -1.2578125em;
  color: rgb(0, 0, 0);
}
.icon-expense .path3:before {
  content: "\e90c";
  margin-left: -1.2578125em;
  color: rgb(255, 255, 255);
}
.icon-purchase:before {
  content: "\e90d";
}
.icon-credit:before {
  content: "\e90e";
}
.icon-calender:before {
  content: "\e90f";
}
.icon-notification:before {
  content: "\e910";
}
