:root {
  --white: #ffffff;
  --black: #000000;
  --darkblack: #181a1c;
  --graylight: #eaeced;
  --lightgary: #808190;
  --darkgray: #404d51;
  --ongray: #9395a2;
  --offgreen: #499343;
  --lightblue: #f6f8fe;
  --bluedark: #3f40ef;
  --darkblue: #1a58ff;
  --limeblue: #1976d2;
  --orange: #fb8500;
  --yellow: #ffba03;
  --lima: #19cbd8;
  --darkgreen: #77ab59;
  --offgray: #f5f7f9;
  --textcolor: #959595;

  --fullcircle: 50%;

  /* --ipadsize: 6 * ((100vw - 320px) / 680); */
}
@font-face {
  font-family: "Rileno Sans";
  src: url("../fonts/RilenoSans-Medium.eot");
  src: url("../fonts/RilenoSans-Medium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/RilenoSans-Medium.woff") format("woff"),
    url("../fonts/RilenoSans-Medium.ttf") format("truetype"),
    url("../fonts/RilenoSans-Medium.svg#RilenoSans-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Rileno Sans";
  src: url("../fonts/RilenoSans-Regular.eot");
  src: url("../fonts/RilenoSans-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/RilenoSans-Regular.woff") format("woff"),
    url("../fonts/RilenoSans-Regular.ttf") format("truetype"),
    url("../fonts/RilenoSans-Regular.svg#RilenoSans-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.title_datearea {
  display: block;
  margin-top: 5px;
}
.outlet_datearea {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.datearea {
  padding-top: 11px;
  width: 100%;
}
.outlet_datearea h1,
.title_datearea h2,
.title_datearea h1 {
  font-family: "Rileno Sans";
  font-size: 30px;
  font-weight: bold;
  color: var(--darkblack);
  text-transform: capitalize;
  margin: 0;
  line-height: normal;
}
.salescount {
  background-color: var(--yellow);
}
.expensecount {
  background-color: var(--limeblue);
}
.purchasecount {
  background-color: var(--lima);
}
.expensecount,
.purchasecount,
.salescount {
  width: 167px;
  height: 167px;
  border-radius: 6px;
  box-shadow: 0 0.5rem 1rem rgba(18, 38, 63, 0.1);
  overflow: hidden;
  position: relative;
}
.purchasecount h2,
.expensecount h2,
.salescount h2 {
  font-family: "Rileno Sans";
  font-weight: 500;
  color: var(--white);
  font-size: 30px;
  margin: 1em 0 0 0.5em;
  text-transform: capitalize;
}
.purchasecount p,
.expensecount p,
.salescount p {
  font-family: "Rileno Sans";
  font-weight: 500;
  color: var(--white);
  font-size: 30px;
  margin: 1em 0 0 0.5em;
}
.expensecount:before {
  content: "";
  position: absolute;
  right: -28px;
  top: 50%;
  transform: translateY(-50%);
  background-image: url(../img/expanes.png);
  width: 94px;
  height: 120px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}
.salescount:before {
  content: "\e907";
  font-family: "icomoon";
  position: absolute;
  right: -28px;
  font-size: 80px;
  color: rgba(255, 255, 255, 0.5);
  top: 50%;
  transform: translateY(-50%);
}
.purchasecount:before {
  content: "";
  font-family: "icomoon";
  position: absolute;
  right: -17px;
  font-size: 80px;
  color: rgba(255, 255, 255, 0.5);
  top: 55%;
  transform: translateY(-50%);
  background-image: url(../img/expane.png);
  width: 70px;
  height: 120px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}
.leftarea .MuiButton-root img {
  width: 45px;
}
.boxarea {
  overflow-x: scroll;
  overflow-y: hidden;
  padding-bottom: 30px;
}
.sales_aera {
  position: relative;
  margin: 2em 0 1.5em;
}
.box_area {
  width: 520px;
  display: flex;
  justify-content: space-between;
}
.scrolltext {
  font-size: 12px;
  margin-bottom: 0;
  position: absolute;
  right: 0;
  bottom: 6px;
  text-align: right;
  color: var(--darkblue);
}
.scrolltext .MuiSvgIcon-root {
  margin-left: 5px;
  margin-top: -9px;
  float: right;
  width: 0.6em;
  height: 0.6em;
}

.weeklychart {
  border-bottom: 2px solid var(--offgray);
  margin: 2em;
}
.totalvstotal {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.gradient-border .icon-indianr {
  margin-right: -10px;
}
.gradient-border {
  background-image: linear-gradient(to left, #77ab59, #77ab59);
  position: absolute;
  z-index: 10;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  top: 2px;
  left: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
}

.ruppes {
  background-color: red;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background-image: linear-gradient(to left, #abedd2, #ffffff);
  position: relative;
  box-shadow: 0 0.5rem 1rem rgb(18, 38, 63, 0.15);
}
.total_cash {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.txt_total {
  margin-left: 10px;
}
.txt_total p {
  font-family: "Rileno Sans";
  font-weight: bold;
  font-size: 30px;
  margin: 0;
  color: var(--darkblack);
  line-height: 1;
  text-align: right;
}
.txt_total h2 {
  margin: 0;
  font-family: "Rileno Sans";
  font-weight: bold;
  font-size: 18px;
  color: var(--darkgreen);
  text-transform: capitalize;
  text-align: right;
}
.totalcredits {
  position: relative;
}
.totalcredits:before {
  content: "";
  width: 2px;
  height: 100%;
  position: absolute;
  left: -11px;
  top: 0;
  background-color: var(--offgray);
}
.gradient-border.creditarea {
  font-size: 25px;
  background-image: linear-gradient(to left, #9592f9, #c2c1f1);
}
.creditarea .icon-credit {
  color: var(--white);
}
.iconarea {
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 30px;
}
html body .iconarea .bp3-control-group > * {
  flex-grow: unset;
  flex-shrink: unset;
}
.iconarea .bp3-input-group .bp3-input {
  box-shadow: none;
}
.iconarea .MuiSvgIcon-root {
  position: absolute;
  right: -3px;
  bottom: 4px;
}
.iconarea
  .bp3-popover-open
  .bp3-control-group
  .bp3-input-group:last-child
  .bp3-input:focus,
.iconarea
  .bp3-popover-open
  .bp3-control-group
  .bp3-input-group:first-child
  .bp3-input:focus {
  border-color: var(--bluedark);
  font-weight: bold;
  border-width: 2px;
  color: var(--bluedark);
}
.iconarea .bp3-control-group .bp3-input-group:first-child {
  width: 60%;
}
.iconarea .bp3-control-group .bp3-input-group.bp3-intent-danger {
  border: 0px solid #727783;
}
.iconarea .bp3-control-group .bp3-input-group:first-child {
  width: 50%;
}
.iconarea .bp3-control-group .bp3-input-group:first-child .bp3-input {
  text-align: left;
  /* border:1px solid #727783; */
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  font-size: 10px;
  border: 0px solid #727783;
  color: var(--darkblack);
  padding: 0.7em 0.5em 0.5em 0.5em;
}
.iconarea .bp3-input-group.bp3-intent-danger .bp3-input:focus {
  box-shadow: none;
}

.iconarea .bp3-control-group .bp3-input-group:last-child {
  padding-right: 0;
  position: relative;
}
.iconarea .bp3-control-group .bp3-input-group:last-child .bp3-input {
  padding-right: 30px;
  background-image: url(../img/calender.svg);
  background-position: 98% center;
  background-repeat: no-repeat;
  background-size: 22px;
  text-align: right;
  border: 1px solid #727783;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  font-size: 10px;
  color: var(--darkblack);
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border: 0px solid #727783;

  padding: 0.7em 40px 0.5em 0.5em;
}
html body .MuiButton-root .MuiButton-label .MuiSvgIcon-root {
  width: 35px;
  height: 35px;
}
.prev_cash {
  position: relative;
  padding-left: 18px;
  margin-top: 10px;
  font-family: "Rileno Sans";
  font-weight: normal;
  color: var(--textcolor);
}
.prev_cash strong {
  color: var(--textcolor);
}
.prev_cash .upicon {
  position: absolute;
  left: 0;
  top: -7px;
  transform: rotate(0deg);
}
.prev_cash .upicon .MuiSvgIcon-root {
  width: 15px;
  height: 15px;
}
.totalcredits .prev_cash .upicon {
  transform: rotate(0deg);
}
html body .bp3-datepicker .DayPicker-Caption {
  background-color: var(--limeblue);
}
html body .bp3-datepicker .bp3-datepicker-caption {
  flex-direction: column;
  width: 60%;
}
html body .bp3-datepicker .bp3-html-select select {
  color: rgba(255, 255, 255, 0.54);
  font-family: "Rileno Sans";
  font-size: 1rem;
  font-weight: 500;
}
html body .bp3-datepicker .bp3-html-select.bp3-datepicker-year-select select {
  color: var(--white);
  font-size: 2.125rem;
  font-weight: bold;
  font-family: "Rileno Sans";
  height: auto;
}
html
  body
  .bp3-datepicker
  .bp3-html-select.bp3-datepicker-month-select
  select
  option {
  color: var(--limeblue);
}
html
  body
  .bp3-datepicker
  .bp3-html-select.bp3-datepicker-year-select
  select
  option {
  font-size: 1rem;
  color: var(--limeblue);
}
html body .bp3-datepicker .DayPicker-Day.DayPicker-Day--selected {
  border-radius: 50%;
}
html body .bp3-divider {
  margin: 0;
  border: 0;
}
html body .bp3-menu {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #1565c0;
}
html body .bp3-menu .bp3-menu-item > .bp3-fill {
  color: var(--white);
  font-family: "Rileno Sans";
  font-weight: 500;
}
html body .bp3-menu .bp3-menu-item.bp3-intent-primary:hover,
html
  body
  .bp3-menu
  .bp3-submenu
  .bp3-popover-target.bp3-popover-open
  > .bp3-intent-primary.bp3-menu-item,
html body .bp3-menu .bp3-menu-item.bp3-intent-primary.bp3-active {
  background-color: var(--limeblue);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
html body .DayPicker .bp3-html-select .bp3-icon,
html body .DayPicker span.bp3-icon.bp3-icon-chevron-left {
  color: var(--white);
}
html body .DayPicker .bp3-html-select.bp3-minimal select:hover,
html body .DayPicker .bp3-select.bp3-minimal select:hover {
  color: rgba(255, 255, 255, 0.54);
}
html
  body
  .bp3-datepicker
  .bp3-html-select.bp3-datepicker-year-select
  .bp3-icon {
  top: 20px;
}
html body .bp3-datepicker .DayPicker-Month {
  margin: 0;
}
html
  body
  .bp3-daterangepicker
  .DayPicker-Day--selected-range-start:not(.DayPicker-Day--selected-range-end):not(.DayPicker-Day--hovered-range-end) {
  border-bottom-right-radius: 50%;
  border-top-right-radius: 50%;
}
html body .bp3-icon > svg:not([fill]) {
  color: var(--white);
}
.outlettotal {
  font-family: "Rileno Sans";
  font-size: 35px;
  color: var(--darkblack);
  font-weight: bold;
}
.allselected {
  width: 100%;
}
.allselected .MuiOutlinedInput-notchedOutline,
.allselected .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: transparent;
}
.allselected .MuiInputLabel-root {
  font-family: "Rileno Sans";
  font-size: 20px;
  font-weight: normal;
  color: var(--ongray);
}
.allselected .MuiFormLabel-root.Mui-focused {
  font-size: 18px;
}
html body .MuiBottomNavigationAction-root.Mui-selected {
  color: transparent;
}
html body .MuiAutocomplete-popupIndicator .MuiIconButton-label {
  background-image: url(../img/selecttag.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 25px;
  width: 28px;
  height: 28px;
}
html body .MuiAutocomplete-popupIndicator .MuiIconButton-label svg {
  display: none;
}
html body .bp3-transition-container {
  z-index: 200;
}
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .outlet_datearea h1,
  .title_datearea h2,
  .title_datearea h1 {
    font-size: 23px;
  }
  .txt_total {
    margin-left: 0;
  }
  .ruppes {
    width: 50px;
    height: 50px;
  }
  .gradient-border {
    width: 45px;
    height: 45px;
  }
  .txt_total h2 {
    font-size: 16px;
  }
  .totalvstotal .totalcash {
    width: 47%;
  }
  .totalvstotal .totalcredits {
    width: 47%;
  }
  .prev_cash {
    font-size: 12px;
    text-align: right;
  }
  .prev_cash .upicon {
    left: 8px;
    top: -12px;
  }
  .prev_cash .upicon .MuiSvgIcon-root {
    width: 10px;
    height: 10px;
  }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .outlet_datearea h1,
  .title_datearea h2,
  .title_datearea h1 {
    font-size: 23px;
  }
  .txt_total {
    margin-left: 0;
  }
  .ruppes {
    width: 50px;
    height: 50px;
  }
  .gradient-border {
    width: 45px;
    height: 45px;
  }
  .txt_total h2 {
    font-size: 16px;
  }
  .totalvstotal .totalcash {
    width: 47%;
  }
  .totalvstotal .totalcredits {
    width: 47%;
  }
  .prev_cash {
    font-size: 12px;
    text-align: right;
  }
  .prev_cash .upicon {
    left: 8px;
    top: -12px;
  }
  .prev_cash .upicon .MuiSvgIcon-root {
    width: 10px;
    height: 10px;
  }
}

@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  .prev_cash {
    font-size: 13px;
    text-align: right;
  }
}
